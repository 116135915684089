<!-- tsx -->
<template>
  <div class="fundsManage">
    <base-header :leftTit="tltle"/>
  </div>
</template>

<script >
import BaseHeader from './component/baseHeader'
export default {
components: {
  BaseHeader
},
  data() {
    return {
      tltle:'划转'

    }
  },
created() {},
mounted() {},
computed: {},
watch: {},
methods: {
  // goBack(){
  //   this.$router.push('/conditionOfAssets')
  // },
},
}
</script>

<style lang='scss' scoped>
// @import url(); 引入公共css类
.fundsManage{
  width: 100%;
}
</style>
